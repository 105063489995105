import React from "react";
import Header from "../components/header";
import ContactUs from "../components/contact-us";
import AboutUs from "./AboutUs";
import Banner from "../components/banner";
// import Forms from "../components/forms";
// import Products from "../components/products";
const Home: React.FC = () => {
  return (
    <div
      style={{
        width: "90%",
        margin: "auto",
      }}
    >
      <Header navItems={["About Us", "Contact Us"]} />
      <Banner />
      <AboutUs />
      <ContactUs />
    </div>
  );
};

export default Home;
