import React from "react";

interface LocationProps {
  address: string;
  phoneNumbers: string;
  addressLink: string;
}

const Location: React.FC<LocationProps> = ({
  address,
  phoneNumbers,
  addressLink,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f9f9f9",
        padding: "10px",
        width: "300px",
        height: "200px",
        border: "1px #dcc",
        borderRadius: 10,
        flex: "1 0 auto", // Each product will flex to take up about 400px
        zIndex: 1,
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div key={address}>
        <p>{address}</p>
        <p>{phoneNumbers}</p>
        <button
          onClick={() => window.open(addressLink, "_blank")}
          style={{
            padding: "10px",
            backgroundColor: "#D9310D",
            color: "white",
            border: "none",
            cursor: "pointer",
            borderRadius: "5px",
            marginLeft: "100px",
            marginTop: "10px",
          }}
        >
          Get Directions
        </button>
      </div>
    </div>
  );
};

export default Location;
