// src/components/Logo.js

const Logo = () => {
  return (
    <svg
      width="200"
      height="100"
      viewBox="0 0 200 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon
        points="10,50 50,10 150,10 190,50 150,90 50,90"
        fill="none"
        stroke="#D9310D"
        strokeWidth="5"
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        fill="#D9310D"
        fontSize="50"
        fontFamily="Arial"
        dy=".35em"
      >
        HSC
      </text>
    </svg>
  );
};

export default Logo;
