import React from "react";
import Location from "../location";
import Forms from "../forms";

interface LocationInfo {
  address: string;
  phoneNumbers: string;
  addressLink: string;
}

const Locations: LocationInfo[] = [
  {
    address:
      "G-2, Silver Plazza Complex, Opp. Rajvee Towers, Beside Bank of Baroda, Near Tube Company, Old Padra Road, Akota, Vadodara, Gujarat - 390020",
    phoneNumbers: "M: (982) 531-1157",
    addressLink:
      "https://www.google.com/maps/dir//Himanshu+Sales+Corporation,+G+2,+Silver+Plaza,+Old+Padra+Rd,+opposite+Rajvee+Towers,+Vadodara,+Gujarat+390020/@22.3051809,73.1521499,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x395fc61c00b60f67:0x1b5b9b19f2dc5eb4!2m2!1d73.1647281!2d22.2914948",
  },
  {
    address:
      " G-1, Saiffee Complex Opp B.R.C., Near Reliance Petrol Pump, Udhana Main Road, Surat, Gujarat-394210",
    phoneNumbers: "M: (982) 531-1156",
    addressLink:
      "https://www.google.com/maps/dir//Himanshu+Sales+Corporation,+Saifee+Complex,+Opposite+Brc+Gate,+nr.+Reliance+Petrol+Pump,+Udhna,+Surat,+Gujarat+394210/@21.1581388,72.8405791,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3be04e2f9e7c909b:0x347449f4b6273431!2m2!1d72.8427732!2d21.1581388",
  },
  {
    address:
      "207, Jawahar Nagar Society, Opp. ICICI Bank, B/S IDBI Bank, Godhra Road, Halol, Panchmahal, Gujarat - 389350",
    phoneNumbers: "M: (982) 531-9459",
    addressLink:
      "https://www.google.com/maps/dir//Himanshu+Sales+Corporation/data=!4m8!4m7!1m0!1m5!1m1!1s0x39607ffe120c0ecf:0x617b331d6b731e09!2m2!1d73.4701698!2d22.5130256",
  },
  {
    address:
      "Opp. Boriyawala Hall, Beside Shivam Imaging Center, Godhra Road, Lunwada, Mahisagar, Gujarat-389230",
    phoneNumbers: "M: (982) 531-9458",
    addressLink:
      "https://www.google.com/maps/dir//Himanshu+Sales+Corporation,+Nr.+N.+S.+Cinema,+opp.+Boriyawala+Hall,+Godhra+Road,+Lunawada,+Gujarat+389230/@23.1246275,73.6038707,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3960af00a8ff6ae3:0x9f4e28fcc3bcfddf!2m2!1d73.6060648!2d23.1246275",
  },
  // You can add more contact entries here
];

const ContactUs: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      className="contactUs"
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h1
          style={{
            fontSize: "2.5em",
            textAlign: "center",
            marginBottom: 20,
            color: "#2c3e50",
          }}
        >
          Contact Us
        </h1>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "10px",
        }}
      >
        <Forms />
      </div>

      <div className="contact-info">
        {Locations.map((contactInfo) => (
          <Location
            address={contactInfo.address}
            phoneNumbers={contactInfo.phoneNumbers}
            addressLink={contactInfo.addressLink}
          />
        ))}
      </div>
      <footer>
        <p>
          <strong>
            For any other queries, please contact us at{" "}
            <a
              href="mailto:
                hsc@himashusales.com"
            >
              hsc-help
            </a>
          </strong>
        </p>
      </footer>
    </div>
  );
};

export default ContactUs;
