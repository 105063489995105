import React from "react";

const Banner = () => {
  return (
    <div>
      <svg
        width="100%"
        height="100%"
        viewBox="10 0 500 50"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0" y="0" width="100%" height="100%" fill="#ffffff" />
        <text
          x="50%"
          y="100%"
          textAnchor="middle"
          fill="red"
          fontSize="25"
          fontFamily="Arial Rounded MT Bold"
          dy="-0.45em"
        >
          Himanshu Sales Corporation
        </text>
      </svg>
    </div>
  );
};

export default Banner;