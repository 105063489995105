import React from "react";

const AboutUs: React.FC = () => {
  return (
    <div className="aboutUs">
      <p className="about-us-intro"
        style={{
          marginTop:"-10px",
          textAlign: "center",
          fontSize: "1.5em",
          color: "#2c3e50",
          marginBottom: 20,
        }}
      >
        Welcome to Himanshu Sales Corporation, your trusted destination for
        top-quality consumer electronics since 1994.
      </p>
      <div
        style={{
          width: "100%",
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          gap: 20,
        }}
      >
        <img
          src="/image.png"
          alt="Himanshu Sales Corporation"
          style={{
            width: "100%",
            height: "30%",
            borderRadius: "10px",
            marginBottom: "10px",
            border: "1px solid",
          }}
        />
        <div>
          <h2 className="about-us-subtitle">Our Journey</h2>
          <p className="about-us-text">
            Founded on the core principle of delivering the best in quality and
            service, Himanshu Sales Corporation began its journey as a humble TV
            showroom in the vibrant city of Surat. Over the decades, our
            commitment to excellence has driven us to expand and evolve,
            transforming into one of Centre & South Gujarat’s leading chains of
            electronic superstores.
          </p>

          <h2 className="about-us-subtitle">Our Growth</h2>
          <p className="about-us-text">
            What started as a small showroom has blossomed into a robust network
            of stores across key locations in Gujarat. Today, we proudly serve
            our customers in Surat, Baroda, Halol, and Lunawada. Each store is a
            testament to our dedication to bringing the latest in consumer
            electronics closer to you.
          </p>

          <h2 className="about-us-subtitle">Our Promise</h2>
          <p className="about-us-text">
            At Himanshu Sales Corporation, we believe in providing more than
            just products; we offer experiences. Our diverse range of
            electronics is carefully curated to meet the evolving needs of our
            customers, ensuring quality, reliability, and satisfaction with
            every purchase. Our knowledgeable and friendly staff are always
            ready to assist you, providing personalized service to help you find
            exactly what you need.
          </p>
        </div>
      </div>
      <h2 className="about-us-subtitle" style={{
        textAlign: "center",
        marginTop: "20px",
      }}>Why Choose Us?</h2>
      <ul className="about-us-list">
        <li>
          <strong>Extensive Selection:</strong> From the latest TVs and home
          appliances to cutting-edge gadgets, we offer an extensive selection of
          top brands and the latest technology.
        </li>
        <li>
          <strong>Customer-Centric Service:</strong> Our customer-first approach
          ensures you receive the highest level of service. We are here to
          assist you at every step of your shopping journey.
        </li>
        <li>
          <strong>Competitive Pricing:</strong> We strive to offer the best
          value for your money, with competitive pricing on all our products.
        </li>
        <li>
          <strong>Community Commitment:</strong> As a local business, we are
          deeply rooted in the community and are committed to contributing
          positively to the areas we serve.
        </li>
      </ul>

      <p className="about-us-closing">
        Join us at Himanshu Sales Corporation and discover why we are Gujarat’s
        preferred choice for consumer electronics. Whether you’re upgrading your
        home, looking for the latest tech innovations, or seeking expert advice,
        we are here to help make your shopping experience exceptional. Thank you
        for being a part of our journey.
      </p>
    </div>
  );
};

export default AboutUs;
