/* create a modal component which will have all the nav items, it will open when hamburger menu is clicked */
import React from "react";
import { Link } from "react-scroll";

interface MenuModalProps {
  navItems: string[];
}

const navItemMap: Record<string,string> = {
  "Products": "products",
  "About Us": "aboutUs",
  "Contact Us": "contactUs",
};

const MenuModal: React.FC<MenuModalProps> = ({ navItems }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "100%",
        right: 0,
        width: "fit-content",
        padding: "0",
        backgroundColor: "white",
        zIndex: 5,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {navItems.map((item, index) => (
        <ul
          key={index}
          style={{
            listStyle: "none",
            display: "flex",
            overflow: "initial",
            margin: 0,
            padding: 0,
          }}
        >
          <li
            style={{
              padding: "10px",
              margin: 0,
              width: "100%",
              textAlign: "center",
            }}
          >
            <Link
              activeClass="active"
              to={navItemMap[item] as string }
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
            >
              {item}
            </Link>
          </li>
        </ul>
      ))}
    </div>
  );
};

export default MenuModal;
