import React, { useEffect, useState } from "react";
import Logo from "../logo";
import "../../App.css"; // import the CSS file
import MenuModal from "../menu-modal";
import { Link } from "react-scroll";

interface HeaderProps {
  navItems: string[];
}

const Header: React.FC<HeaderProps> = ({ navItems }) => {
  const [showHamburger, setShowHamburger] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleHamburgerMenu = () => {
    setIsModalOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && showHamburger) {
        setShowHamburger(false);
        setIsModalOpen(false);
      }
      if (window.innerWidth <= 768 && !showHamburger) {
        setShowHamburger(true);
      }
    };
  handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showHamburger]);

  return (
    <div>
      <header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 20px",
          backgroundColor: "white",
        }}
      >
        <div>
          <Logo />
        </div>
        <div>
          {!showHamburger ? (
            <nav>
              <ul
                style={{
                  listStyle: "none",
                  display: "flex",
                  overflow: "initial", // added this line to fix the overflow issue
                  gap: 20,
                  margin: 0,
                  padding: 0,
                }}
              >
                <li>
                  <a
                    href="/"
                    style={{
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Home
                  </a>
                </li>
                {/* <li>
                  <Link
                    activeClass="active"
                    to="products"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                  >
                    Products
                  </Link>
                </li> */}
                <li>
                  <Link
                    activeClass="active"
                    to="aboutUs"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="contactUs"
                    spy={true}
                    smooth={true}
                    offset={-10}
                    duration={500}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
          ) : (
            <div style={{ position: "relative" }}>
              <button
                onClick={toggleHamburgerMenu}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <div
                  className="hamburger-menu"
                  style={{
                    background: "transparent",
                    border: "none",
                    padding: 0,
                    zIndex: 10,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "1.5rem",
                    height: "1rem",
                  }}
                >
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </button>
              {isModalOpen && <MenuModal navItems={navItems} />}
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;
